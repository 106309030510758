define('dispatcher/current-user/service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: null,

    load: function load() {
      var _this = this;

      var userId = this.get('session.data.authenticated.user.id');
      if (Ember.isPresent(userId)) {
        return this.get('store').findRecord('user', userId).then(function (user) {
          _this.set('user', user);
        }).catch(function () {
          _this.get('session').invalidate();
        });
      } else {
        return Ember.RSVP.resolve();
      }
    }
  });
});