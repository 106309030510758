define('dispatcher/feed-version/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    feed: _emberData.default.belongsTo('feed', { async: true }),
    active_for_feed: _emberData.default.hasMany('feed', { async: true, inverse: 'active_feed_version' }),
    feed_version_imports: _emberData.default.hasMany('feed-version-import', { async: true }),
    feed_version_infos: _emberData.default.hasMany('feed-version-infos', { async: true }),
    changesets_imported_from_this_feed_version: _emberData.default.hasMany('changeset', { async: true, inverse: 'imported_from_feed_version' }),
    issues: _emberData.default.hasMany('issue'),

    md5: _emberData.default.attr('string'),
    earliest_calendar_date: _emberData.default.attr('date'),
    latest_calendar_date: _emberData.default.attr('date'),
    tags: _emberData.default.attr(),
    fetched_at: _emberData.default.attr('date'),
    imported_at: _emberData.default.attr('date'),
    import_level: _emberData.default.attr('number'),
    import_status: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    is_active_feed_version: _emberData.default.attr('boolean'),
    url: _emberData.default.attr('string'),
    download_url: _emberData.default.attr('string'),
    feedvalidator_url: _emberData.default.attr('string'),

    sha1: Ember.computed.alias('id'),
    import_level_at_least_level_one: Ember.computed.gte('import_level', 1),
    import_level_is_level_two: Ember.computed.equal('import_level', 2),
    import_level_at_least_level_two: Ember.computed.gte('import_level', 2),
    import_level_at_least_level_three: Ember.computed.gte('import_level', 3),
    import_level_at_least_level_four: Ember.computed.gte('import_level', 4),

    short_sha1: Ember.computed('sha1', function () {
      return this.id.slice(0, 8) + "…";
    }),

    enqueue: function enqueue(import_level) {
      var adapter = this.get('store').adapterFor('feed');
      var url = adapter.urlPrefix() + '/webhooks/feed_eater';
      return adapter.ajax(url, 'post', {
        data: {
          feed_onestop_id: this.get('feed.onestop_id'),
          feed_version_sha1: this.get('sha1'),
          import_level: import_level
        }
      });
    }
  });
});