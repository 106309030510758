define('dispatcher/route/model', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		onestop_id: Ember.computed.alias('id'),
		name: _emberData.default.attr('string'),
		vehicle_type: _emberData.default.attr('string'),
		created_at: _emberData.default.attr('date'),
		updated_at: _emberData.default.attr('date'),
		stops_served_by_route: _emberData.default.attr(),
		issues: _emberData.default.hasMany('issue')
	});
});