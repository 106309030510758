define("dispatcher/helpers/underscored-string-to-title-case", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.underscoredStringToTitleCase = underscoredStringToTitleCase;
  function underscoredStringToTitleCase(params) {
    return params[0].split("_").map(function (word) {
      return word.capitalize();
    }).join(" ");
  }

  exports.default = Ember.Helper.helper(underscoredStringToTitleCase);
});