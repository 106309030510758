define('dispatcher/feed/serializer', ['exports', 'dispatcher/application/serializer', 'ember-data'], function (exports, _serializer, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'onestop_id',
    attrs: {
      changesets_imported_from_this_feed: {
        key: 'changesets_imported_from_this_feed',
        deserialize: 'ids'
      },
      issues: {
        embedded: 'always'
      }
    },
    normalize: function normalize(modelClass, resourceHash) {
      resourceHash.operators = resourceHash.operators_in_feed.map(function (oif) {
        return oif.operator_onestop_id;
      });
      return this._super.apply(this, arguments);
    }
  });
});