define("dispatcher/helpers/to-fixed", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toFixed = toFixed;
  function toFixed(params /*, hash*/) {
    var value = params[0];
    var precision = params[1];
    if (value != undefined && typeof value.toFixed === "function") {
      return value.toFixed(precision);
    } else {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(toFixed);
});