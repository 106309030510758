define('dispatcher/components/time-since-with-mouseover/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TimeSinceWithMouseoverComponent = Ember.Component.extend({
    tagName: 'abbr',
    dateAsString: Ember.computed('date', function () {
      var date = this.get('date');
      if (Ember.isPresent(date)) {
        return this.get('date').toString();
      }
    }),
    attributeBindings: ['title'],
    title: Ember.computed.alias('dateAsString')
  });

  TimeSinceWithMouseoverComponent.reopenClass({
    positionalParams: 'date'
  });

  exports.default = TimeSinceWithMouseoverComponent;
});