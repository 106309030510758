define('dispatcher/route-stop-pattern/serializer', ['exports', 'ember-data', 'dispatcher/application/serializer'], function (exports, _emberData, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'onestop_id',
    attrs: {
      issues: {
        embedded: 'always'
      }
    }
  });
});