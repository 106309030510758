define('dispatcher/issues/route-geometry/show/controller', ['exports', 'dispatcher/mixins/issues-controller', 'dispatcher/mixins/issues-resolving-changeset-controller', 'dispatcher/mixins/issues-close-controller'], function (exports, _issuesController, _issuesResolvingChangesetController, _issuesCloseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_issuesController.default, _issuesResolvingChangesetController.default, _issuesCloseController.default, {
    editableLeafletObjects: {
      // Some layers should not be editable, so this keeps track of those that are.
      // leaflet layer id -> onestop_id
    },
    rootRoute: 'issues.route-geometry',
    postSuccessTransition: function postSuccessTransition() {
      var queryParamsObject = this.queryParamsObject();
      this.transitionToRoute('issues.route-geometry.index', { queryParams: queryParamsObject });
    },
    postCloseTransition: function postCloseTransition() {
      var queryParamsObject = this.queryParamsObject();
      this.transitionToRoute('issues.route-geometry.index', { queryParams: queryParamsObject });
    },
    getChanges: function getChanges() {
      var entities = [];
      entities = entities.concat(this.store.peekAll('route-stop-pattern').filter(function (e) {
        return e.get('hasDirtyAttributes') && typeof e.changedAttributes().geometry !== "undefined";
      }));
      entities = entities.concat(this.store.peekAll('stop').filter(function (e) {
        return e.get('hasDirtyAttributes') && typeof e.changedAttributes().geometry !== "undefined";
      }));
      return entities.map(function (e) {
        var ret = {};
        ret['action'] = 'createUpdate';
        ret['issuesResolved'] = [parseInt(this.model.selectedIssue.id)];
        ret[e.entityType()] = e.toChange();
        return ret;
      }, this);
    },
    actions: {
      actionDrawEdited: function actionDrawEdited(EditedEvent) {
        // applying changes
        var layers = EditedEvent.layers._layers;
        Object.keys(layers).forEach(function (id) {
          var editedLayer = layers[id];
          var onestop_id = this.get('editableLeafletObjects')[id];
          if (onestop_id.match(/^s\-/)) {
            if (this.get('model.issueStops')) {
              this.get('model.issueStops').forEach(function (stop) {
                if (stop.get('onestop_id') === onestop_id) {
                  var latlng = editedLayer.getLatLng();
                  stop.setCoordinates([latlng.lng, latlng.lat]);
                }
              });
            }
          } else if (onestop_id.match(/^r\-/)) {
            this.get('model.issueRouteStopPatterns').forEach(function (rsp) {
              if (rsp.get('onestop_id') === onestop_id) {
                var latlngs = editedLayer.getLatLngs();
                rsp.setCoordinates(latlngs.map(function (latlng) {
                  return [latlng.lng, latlng.lat];
                }));
              }
            });
          }
        }, this);
      },
      editEntityAdded: function editEntityAdded(leafletId, onestop_id) {
        this.get('editableLeafletObjects')[leafletId] = onestop_id;
      }
    }
  });
});