define('dispatcher/users/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('user', {});
    },
    actions: {
      create: function create() {
        var self = this;
        var user = self.currentModel;
        user.save().then(function () {
          self.transitionTo('users.show', user);
        });
      }
    }
  });
});