define('dispatcher/components/user-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'form',
    userTypes: ["community_builder", "data_enthusiast", "app_developer", "hardware_vendor", "consultant", "transit_agency_staff", "other_public_agency_staff"],
    actions: {
      handleFocus: function handleFocus(select) {
        select.actions.open();
      }
    }
  });
});