define('dispatcher/issues/index/controller', ['exports', 'dispatcher/mixins/paginated-sortable-controller'], function (exports, _paginatedSortableController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatedSortableController.default, {
    selectedFeed: '',

    hasNextPage: Ember.computed("model.feeds.meta.next", function () {
      if (Ember.isPresent(this.get('model.feeds.meta.next'))) {
        return true;
      } else {
        return false;
      }
    }),

    actions: {
      feedClicked: function feedClicked(feed) {
        var feed_id = feed === null ? '' : feed.get('onestop_id');
        this.set('selectedFeed', feed_id);
      }
    }
  });
});