define('dispatcher/mixins/issues-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: ['of_feed_entities', 'open', 'issue_type', 'per_page', 'of_entity', 'sort_key', 'sort_order'],
    issue_type: '',
    of_feed_entities: '',
    open: true,
    per_page: '50',
    of_entity: '',
    sort_key: 'created_at',
    sort_order: 'desc',
    queryParamsObject: function queryParamsObject() {
      var queryParams = {};
      var self = this;
      this.get('queryParams').forEach(function (param) {
        queryParams[param] = self.get(param);
      });
      return queryParams;
    },
    actions: {
      issueClicked: function issueClicked(issue) {
        var queryParamsObject = this.queryParamsObject();
        this.transitionToRoute(this.rootRoute + '.show', issue.id, { queryParams: queryParamsObject });
      },
      typeChanged: function typeChanged(selected) {

        this.set('issue_type', selected || '');
        var queryParamsObject = this.queryParamsObject();
        this.transitionToRoute(this.rootRoute + '.index', { queryParams: queryParamsObject });
      }
    }
  });
});