define('dispatcher/issues/station-hierarchy/show/route', ['exports', 'dispatcher/mixins/issues-route'], function (exports, _issuesRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_issuesRoute.default, {
    currentUser: Ember.inject.service(),
    model: function model(params) {
      this.store.unloadAll('changeset');
      this.store.unloadAll('change_payload');
      this.store.unloadAll('stop');
      this.store.unloadAll('stop-station');

      var self = this;
      return self.store.findRecord('issue', params['issue_id'], { reload: true }).then(function (selectedIssue) {
        // Changeset
        var changeset = self.store.createRecord('changeset', {
          user: self.get('currentUser.user'),
          notes: 'Issue resolution:'
        });
        changeset.get('change_payloads').createRecord();
        // Users
        var users = self.store.query('user', { per_page: false });

        // Stops
        var stops = [];
        selectedIssue.get('entities_with_issues').forEach(function (entity) {
          if (entity.get('onestop_id').split('-')[0] === 's') {
            stops.push(entity.get('onestop_id').split('<')[0]);
          }
        });
        return self.store.query('stop-station', { onestop_id: stops.join(',') }).then(function (stops) {
          var bounds = new L.latLngBounds(stops.map(function (stop) {
            return new L.latLng(stop.get('coordinates'));
          }));
          var latLng = bounds.getCenter();
          return Ember.RSVP.hash({
            selectedIssue: selectedIssue,
            changeset: changeset,
            lat: latLng.lat,
            lng: latLng.lng,
            users: users,
            stops: stops
          });
        });
      });
    }
  });
});