define('dispatcher/feed-version-import/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    feed: _emberData.default.belongsTo('feed', { async: true }),
    feed_version: _emberData.default.belongsTo('feed-version', { async: true }),
    success: _emberData.default.attr('boolean', { allowNull: true }),
    import_log: _emberData.default.attr('string'),
    import_level: _emberData.default.attr('number'),
    exception_log: _emberData.default.attr('string'),
    validation_report: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    feed_schedule_imports: _emberData.default.attr(),

    importStatusCssClass: Ember.computed('success', function () {
      switch (this.get('success')) {
        case true:
          return 'success';
        case false:
          return 'danger';
        case null:
          return 'active';
      }
    })
  });
});