define('dispatcher/mixins/feed-params-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      active_feed_version_update: {
        refreshModel: true
      },
      active_feed_version_expired: {
        refreshModel: true
      },
      active_feed_version_valid: {
        refreshModel: true
      },
      active_feed_version_import_level: {
        refreshModel: true
      },
      latest_fetch_exception: {
        refreshModel: true
      },
      latest_feed_version_import_status: {
        refreshModel: true
      },
      feed_format: {
        refreshModel: true
      },
      tag_key: {
        refreshModel: true
      },
      tag_value: {
        refreshModel: true
      }
    }
  });
});