define('dispatcher/issue/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    typeDescription: Ember.inject.service('issue-type-desc'),
    issue_type: _emberData.default.attr('string'),
    issue_category: _emberData.default.attr('string'),
    details: _emberData.default.attr('string'),
    open: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    created_by_changeset_id: _emberData.default.attr('number'),
    resolved_by_changeset_id: _emberData.default.attr('number'),
    entities_with_issues: _emberData.default.hasMany('entity-with-issue', { async: true, inverse: 'issue' }),
    imported_from_feed_onestop_id: _emberData.default.attr('string'),
    imported_from_feed_version_sha1: _emberData.default.attr('string'),

    computeTypeDescription: Ember.computed('issue_type', function () {
      return this.get('typeDescription').typeDescription(this.get('issue_type'));
    })
  });
});