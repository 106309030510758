define('dispatcher/issues/station-hierarchy/show/controller', ['exports', 'dispatcher/mixins/issues-controller', 'dispatcher/mixins/issues-resolving-changeset-controller', 'dispatcher/mixins/issues-close-controller'], function (exports, _issuesController, _issuesResolvingChangesetController, _issuesCloseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_issuesController.default, _issuesResolvingChangesetController.default, _issuesCloseController.default, {
    zoom: 14,

    rootRoute: 'issues.station-hierarchy',

    postSuccessTransition: function postSuccessTransition() {
      var queryParamsObject = this.queryParamsObject();
      this.transitionToRoute(this.rootRoute + '.index', { queryParams: queryParamsObject });
    },

    postCloseTransition: function postCloseTransition() {
      var queryParamsObject = this.queryParamsObject();
      this.transitionToRoute(this.rootRoute + '.index', { queryParams: queryParamsObject });
    },

    getChanges: function getChanges() {
      var entities = [];
      entities = entities.concat(this.store.peekAll('stop-station').filter(function (e) {
        return e.get('hasDirtyAttributes');
      }));
      entities = entities.concat(this.store.peekAll('stop-platform').filter(function (e) {
        return e.get('hasDirtyAttributes');
      }));
      entities = entities.concat(this.store.peekAll('stop-egress').filter(function (e) {
        return e.get('hasDirtyAttributes');
      }));
      var self = this;
      return entities.map(function (e) {
        var ret = {};
        ret['action'] = 'createUpdate';
        ret['issuesResolved'] = [parseInt(self.model.selectedIssue.id)];
        ret[e.entityType()] = e.toChange();
        return ret;
      });
    }
  });
});