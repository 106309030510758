define('dispatcher/feed-version-imports/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('feed-version-import', params['feed_version_import_id']);
    },
    actions: {}
  });
});