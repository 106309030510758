define('dispatcher/feeds/show/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      sort_key: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.store.unloadAll('feed');

      var sort_key = params.sort_key || 'earliest_calendar_date';
      var feed = this.store.findRecord('feed', params['feed_id']);
      var routeGeometryIssues = this.store.query('issue', { of_feed_entities: params['feed_id'], category: 'route_geometry', per_page: 0, total: true });
      var stationHierarchyIssues = this.store.query('issue', { of_feed_entities: params['feed_id'], category: 'station_hierarchy', per_page: 0, total: true });
      var feedVersions = this.store.query('feed-version', { per_page: 100, sort_key: sort_key, sort_order: 'desc', feed_onestop_id: params['feed_id'] });

      var adapter = this.get('store').adapterFor('feed');
      var feedVersionUpdateStatistics = adapter.ajax(adapter.urlForFindRecord(params['feed_id'], 'feeds') + '/feed_version_update_statistics');

      var self = this;
      return Ember.RSVP.hash({
        feed: feed,
        feedVersions: feedVersions,
        routeGeometryIssues: routeGeometryIssues,
        stationHierarchyIssues: stationHierarchyIssues,
        feedVersionUpdateStatistics: feedVersionUpdateStatistics
      }).then(function (model) {
        var i = model.feedVersions.map(function (i) {
          return i.id;
        });
        var feedVersionInfoStatistics = i.length == 0 ? [] : self.store.query('feed-version-info', { 'feed_version_sha1': i.join(','), type: 'FeedVersionInfoStatistics', per_page: false });
        return Ember.RSVP.hash({
          feed: model.feed,
          feedVersions: model.feedVersions,
          routeGeometryIssues: routeGeometryIssues,
          stationHierarchyIssues: stationHierarchyIssues,
          feedVersionInfoStatistics: feedVersionInfoStatistics,
          feedVersionUpdateStatistics: feedVersionUpdateStatistics
        });
      });
    },
    actions: {
      feedFetchStarted: function feedFetchStarted() {
        this.refresh();
      }
    }
  });
});