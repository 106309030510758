define('dispatcher/users/sign-in/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    identification: null,
    password: null,

    actions: {
      authenticate: function authenticate() {
        var credentials = this.getProperties('identification', 'password');
        var authenticator = 'authenticator:jwt';

        this.get('session').authenticate(authenticator, credentials);
      }
    }
  });
});