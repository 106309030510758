define('dispatcher/stations/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    queryParams: {
      zoom: {
        replace: true
      },
      lat: {
        replace: true
      },
      lng: {
        replace: true
      },
      bbox: {
        replace: true,
        refreshModel: true
      },
      min_platforms: {
        replace: true,
        refreshModel: true
      },
      min_egresses: {
        replace: true,
        refreshModel: true
      },
      exclude: {
        replace: true,
        refreshModel: true
      }
    },
    model: function model(params) {
      var bbox = params.bbox;
      var min_platforms = parseInt(params.min_platforms);
      var min_egresses = parseInt(params.min_egresses);
      // Changeset
      var changeset = this.store.createRecord('changeset', {
        user: this.get('currentUser.user'),
        notes: 'Station editor:'
      });
      changeset.get('change_payloads').createRecord();
      // Stops
      var q = {
        bbox: bbox,
        per_page: 1000,
        total: false,
        exclude: params.exclude
      };
      if (min_platforms > 0) {
        q.min_platforms = min_platforms;
      }
      if (min_egresses > 0) {
        q.min_egresses = min_egresses;
      }
      return Ember.RSVP.hash({
        changeset: changeset,
        stops: this.store.query('stop-station', q)
      });
    }
  });
});