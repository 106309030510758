define('dispatcher/components/user-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    userMatcher: function userMatcher(user, term) {
      return (user.get('name') + ' ' + user.get('email')).indexOf(term);
    }
  });
});