define('dispatcher/route-stop-pattern/model', ['exports', 'ember-data', 'dispatcher/entity-with-activity/model'], function (exports, _emberData, _model) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _model.default.extend({
		created_or_updated_in_changeset: _emberData.default.belongsTo('changeset', { async: true }),
		identifiers: _emberData.default.attr(),
		trips: _emberData.default.attr(),
		stop_distances: _emberData.default.attr(),
		stop_pattern: _emberData.default.attr(),
		color: _emberData.default.attr('string'),
		route_onestop_id: _emberData.default.attr('string', { readOnly: true }),
		onestop_id: Ember.computed.alias('id'),
		created_at: _emberData.default.attr('date'),
		updated_at: _emberData.default.attr('date'),
		geometry: _emberData.default.attr(),
		tags: _emberData.default.attr(),
		issues: _emberData.default.hasMany('issue'),
		patterns: [
			// NOTE: this is commented out for now, because we have issues loading Leaflet
			// {offset: 0, repeat: 20, symbol: L.Symbol.arrowHead({pixelSize: 12, pathOptions: {fillOpacity: 1, weight: 0}})}
		],
		stopsWithDistances: Ember.computed('stop_pattern', function () {
			var self = this;
			var args = {};
			args.promise = Ember.RSVP.all(this.get('stop_pattern').map(function (stop_onestop_id, index) {
				return Ember.RSVP.hash({ stop: self.store.findRecord('stop', stop_onestop_id), distance: self.get('stop_distances')[index] });
			}));
			return Ember.ArrayProxy.extend(Ember.PromiseProxyMixin).create(args);
		}),
		coordinates: Ember.computed('geometry', function () {
			return this.get('geometry').coordinates.map(function (coord) {
				return coord.slice().reverse();
			});
		}),
		setCoordinates: function setCoordinates(coords) {
			this.set('geometry', { type: 'LineString',
				coordinates: coords.map(function (c) {
					return c.map(function (n) {
						return parseFloat(n.toFixed(5));
					});
				})
			});
		},
		entityType: function entityType() {
			return 'routeStopPattern';
		},
		toChange: function toChange() {
			return {
				onestopId: this.get('onestop_id'),
				stopPattern: this.get('stop_pattern'),
				geometry: {
					type: "LineString",
					coordinates: this.get('geometry').coordinates
				}
			};
		}
	});
});