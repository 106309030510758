define('dispatcher/feed-version/serializer', ['exports', 'dispatcher/application/serializer', 'ember-data'], function (exports, _serializer, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'sha1',
    attrs: {
      issues: {
        embedded: 'always'
      }
    }
  });
});