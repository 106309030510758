define('dispatcher/issue/serializer', ['exports', 'ember-data', 'dispatcher/application/serializer'], function (exports, _emberData, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    primaryKey: 'id',

    attrs: {
      entities_with_issues: { embedded: 'always' }
    }
  });
});