define('dispatcher/stop-platform/model', ['exports', 'ember-data', 'dispatcher/stop/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    routes_serving_stop: _emberData.default.attr(),
    parent_stop: _emberData.default.belongsTo('stop-station', { modelFor: 'stop-station' }),
    parent_stop_onestop_id: Ember.computed('parent_stop', {
      get: function get(key) {
        return this.get('parent_stop').get('id');
      },
      set: function set(key, value) {
        this.set('parent_stop', value);
      }
    }),
    issues: _emberData.default.hasMany('issue'),
    entityType: function entityType() {
      return 'stopPlatform';
    },
    toChange: function toChange() {
      return {
        onestopId: this.id,
        parentStopOnestopId: this.get('parent_stop').get('id'),
        name: this.get('name'),
        timezone: this.get('timezone'),
        geometry: this.get('geometry'),
        geometryReversegeo: this.get('geometry_reversegeo')
      };
    }
  });
});