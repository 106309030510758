define('dispatcher/issues/new/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      this.set('feed_onestop_id', transition.params['issues.new'].feed_id);
    },
    model: function model(params) {
      params['imported_from_feed'] = this.get('feed_onestop_id');
      return Ember.RSVP.hash({
        stops: this.store.query('stop', params),
        route_stop_patterns: this.store.query('route_stop_pattern', params)
      });
    },
    actions: {
      saveIssue: function saveIssue(issueData) {
        var issue = this.store.createRecord('issue', issueData);
        issue.save();
      }
    }
  });
});