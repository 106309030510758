define('dispatcher/stop-station/model', ['exports', 'ember-data', 'dispatcher/stop/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function next_fragment(entities, separator) {
    var ids = entities.map(function (i) {
      return i.id.split(separator)[1];
    });
    var fragment = "";
    for (var i = 0; i < 1000; i++) {
      fragment = String(i);
      if (ids.indexOf(fragment) === -1) {
        break;
      }
    }
    return fragment;
  }

  exports.default = _model.default.extend({
    routes_serving_stop_and_platforms: _emberData.default.attr(),
    stop_platforms: _emberData.default.hasMany('stop-platform', { modelFor: 'stop-platform', inverse: 'parent_stop' }),
    stop_egresses: _emberData.default.hasMany('stop-egress', { modelFor: 'stop-egress', inverse: 'parent_stop' }),
    issues: _emberData.default.hasMany('issue'),
    stationPlatformLines: Ember.computed('coordinates', 'stop_platforms.@each.coordinates', function () {
      var origin = this.get('coordinates');
      return this.get('stop_platforms').map(function (stop_platform) {
        return [origin, stop_platform.get('coordinates')];
      });
    }),
    stationEgressLines: Ember.computed('coordinates', 'stop_egresses.@each.coordinates', function () {
      var origin = this.get('coordinates');
      return this.get('stop_egresses').map(function (stop_platform) {
        return [origin, stop_platform.get('coordinates')];
      });
    }),
    newPlatform: function newPlatform() {
      var separator = '<';
      var fragment = next_fragment(this.get('stop_platforms'), separator);
      return this.get('stop_platforms').createRecord({
        id: this.id + separator + fragment,
        timezone: this.get('timezone'),
        geometry: this.get('geometry_centroid'),
        name: 'New Platform'
      });
    },
    newEgress: function newEgress() {
      var separator = '>';
      var fragment = next_fragment(this.get('stop_egresses'), separator);
      return this.get('stop_egresses').createRecord({
        id: this.id + separator + fragment,
        timezone: this.get('timezone'),
        geometry: this.get('geometry_centroid'),
        name: 'New Egress'
      });
    }
  });
});