define('dispatcher/feed/model', ['exports', 'ember-data', 'dispatcher/entity-with-activity/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    feed_versions: _emberData.default.hasMany('feed-version', { async: true }),
    active_feed_version: _emberData.default.belongsTo('feed-version', { async: true, inverse: 'active_for_feed' }),
    created_or_updated_in_changeset: _emberData.default.belongsTo('changeset', { async: true }),
    changesets_imported_from_this_feed: _emberData.default.hasMany('changeset', { async: true, inverse: 'imported_from_feed' }),
    issues: _emberData.default.hasMany('issue'),

    operators: _emberData.default.hasMany('operator', {
      async: true
    }),
    operators_in_feed: _emberData.default.attr(),
    import_level_of_active_feed_version: _emberData.default.attr('number'),
    import_status: _emberData.default.attr('string'),
    urls: _emberData.default.attr(),
    type: _emberData.default.attr('string'),
    authorization: _emberData.default.attr(),
    tags: _emberData.default.attr(),
    feed_format: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    license_name: _emberData.default.attr('string'),
    license_url: _emberData.default.attr('string'),
    license_use_without_attribution: _emberData.default.attr('string'),
    license_attribution_text: _emberData.default.attr('string'),
    license_create_derived_product: _emberData.default.attr('string'),
    license_redistribute: _emberData.default.attr('string'),
    last_sha1: _emberData.default.attr('string'),
    last_fetched_at: _emberData.default.attr('date'),
    last_imported_at: _emberData.default.attr('date'),
    feed_versions_count: _emberData.default.attr('number'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    feedFormatIsGtfsStatic: Ember.computed.equal('type', null),
    onestop_id: Ember.computed.alias('id'),
    hasIssues: Ember.computed('issues', function () {
      return this.get('issues').get('length') > 0;
    }),
    fetchStatusCssClass: Ember.computed('import_status', function () {
      if (this.get('issues').get('firstObject')) {
        return 'danger';
      } else if (this.get('last_fetched_at')) {
        return 'success';
      } else {
        return '';
      }
    }),
    entityType: function entityType() {
      return 'feed';
    },
    // TODO: move toChange to a mixin
    toChange: function toChange() {
      var _this = this;

      var change = {
        onestopId: this.id
      };
      // TODO: move list of attributes
      ['urls', 'tags', 'name', 'license_name', 'license_url', 'license_use_without_attribution', 'license_attribution_text', 'license_create_derived_product', 'license_redistribute', 'operators_in_feed'].map(function (attribute) {
        if (Object.keys(_this.changedAttributes()).includes(attribute)) {
          change[attribute] = _this.get(attribute);
        }
      });
      return change;
    },
    enqueue: function enqueue(importLevel) {
      var adapter = this.get('store').adapterFor('feed');
      var url = adapter.urlPrefix() + '/webhooks/feed_eater';
      return adapter.ajax(url, 'post', {
        data: {
          feed_onestop_id: this.get('onestop_id'),
          import_level: importLevel
        }
      });
    }
  });
});