define('dispatcher/stop/model', ['exports', 'ember-data', 'dispatcher/entity-with-activity/model'], function (exports, _emberData, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    created_or_updated_in_changeset: _emberData.default.belongsTo('changeset', { async: true }),
    onestop_id: Ember.computed.alias('id'),
    name: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    geometry: _emberData.default.attr(),
    geometry_reversegeo: _emberData.default.attr(),
    geometry_centroid: _emberData.default.attr(),
    tags: _emberData.default.attr(),
    issues: _emberData.default.hasMany('issue'),
    timezone: _emberData.default.attr('string'),
    coordinates: Ember.computed('geometry', 'geometry_reversegeo', function () {
      var c = this.get('geometry');
      if (c.type == 'Point') {
        // return geometry
      } else {
        // return geometry_reversegeo, or if's not set, the geometry_centroid
        c = this.get('geometry_reversegeo') || this.get('geometry_centroid');
      }
      return c.coordinates.slice().reverse();
    }),
    setCoordinates: function setCoordinates(value) {
      // If geometry is a Point, update geometry. Otherwise, update geometry_reversegeo.
      // This will set geometry_reversegeo if it's not initially set for a Polygon.
      var g = { type: 'Point', coordinates: value.map(function (c) {
          return parseFloat(c.toFixed(5));
        }) };
      var c = this.get('geometry');
      if (c.type == 'Point') {
        console.log('setCoordinates geometry');
        this.set('geometry', g);
      } else {
        console.log('setCoordinates geometry_reversegeo');
        this.set('geometry_reversegeo', g);
      }
    },
    entityType: function entityType() {
      return 'stop';
    },
    toChange: function toChange() {
      return {
        onestopId: this.id,
        name: this.get('name'),
        timezone: this.get('timezone'),
        geometry: this.get('geometry'),
        geometryReversegeo: this.get('geometry_reversegeo')
      };
    }
  });
});