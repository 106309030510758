define('dispatcher/components/service-schedule-chart/component', ['exports', 'd3-selection', 'd3-array', 'd3-shape', 'd3-scale', 'd3-time-format', 'd3-axis'], function (exports, _d3Selection, _d3Array, _d3Shape, _d3Scale, _d3TimeFormat, _d3Axis) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var run = Ember.run,
      get = Ember.get;


  function parseModel(model) {
    var data = model.get('feed_version_infos').filterBy('type', 'FeedVersionInfoStatistics').get('firstObject.json');
    if (!data) {
      return;
    }
    var scheduled_service = data.scheduled_service;
    if (!scheduled_service) {
      return;
    }
    return {
      id: model.get('id'),
      short_sha1: model.get('short_sha1'),
      values: Object.keys(scheduled_service).map(function (k) {
        return { date: (0, _d3TimeFormat.isoParse)(k), value: +scheduled_service[k] / 3600.0 };
      })
    };
  }

  // https://github.com/brzpegasus/ember-d3/blob/master/tests/dummy/app/components/simple-circles.js
  // https://bl.ocks.org/mbostock/3884955
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    classNames: ['service-schedule-chart-svg'],
    width: 720,
    height: 100,
    model: null,
    models: [],
    didReceiveAttrs: function didReceiveAttrs() {
      // Render
      run.scheduleOnce('render', this, this.drawChart);
    },
    parseModels: function parseModels() {
      var models = get(this, 'models') || [];
      var model = get(this, 'model');
      if (model) {
        models.push(model);
      }
      var parsedModels = [];
      var idx = 0;
      models.forEach(function (model) {
        var parsedModel = parseModel(model);
        if (!parsedModel) {
          return;
        }
        idx += 1;
        parsedModel.idx = idx;
        parsedModels.push(parsedModel);
      });
      return parsedModels;
    },
    drawChart: function drawChart() {
      // Setup
      var margin = { top: 20, right: 100, bottom: 20, left: 50 };
      var svg = (0, _d3Selection.select)(this.element);
      svg.selectAll("*").remove(); // clear
      var width = $(this.element).width() - margin.left - margin.right;
      var height = $(this.element).height() - margin.top - margin.bottom;
      var g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Convert
      var series = this.parseModels();
      if (series.length == 0) {
        g.append("text").attr("class", "text").attr("x", width + 15).attr("y", 0).style("font", "12px sans-serif").text("No data");
      }

      // Axes
      var x = (0, _d3Scale.scaleTime)().rangeRound([0, width]);
      var y = (0, _d3Scale.scaleLinear)().rangeRound([height, 0]);
      var z = (0, _d3Scale.scaleOrdinal)(_d3Scale.schemeCategory10);
      var l = (0, _d3Shape.line)().x(function (d) {
        return x(d.date);
      }).y(function (d) {
        return y(d.value);
      });

      // Highlights
      function mouseover(d, i) {
        (0, _d3Selection.select)('.line#line-' + d.id).style('stroke-width', 5).each(function () {
          return this.parentNode.appendChild(this);
        });
        (0, _d3Selection.select)('.text#text-' + d.id).style('text-decoration', 'underline');
      }
      function mouseout(d, i) {
        (0, _d3Selection.select)('.line#line-' + d.id).style('stroke-width', 1.5);
        (0, _d3Selection.select)('.text#text-' + d.id).style('text-decoration', 'none');
      }

      // Domain
      x.domain([(0, _d3Array.min)(series, function (s) {
        return (0, _d3Array.min)(s.values, function (d) {
          return d.date;
        });
      }), (0, _d3Array.max)(series, function (s) {
        return (0, _d3Array.max)(s.values, function (d) {
          return d.date;
        });
      })]);
      y.domain([0, (0, _d3Array.max)(series, function (s) {
        return (0, _d3Array.max)(s.values, function (d) {
          return d.value;
        });
      })]);
      z.domain(series.map(function (s) {
        return s.id;
      }));

      // Draw axes
      g.append("g").attr("class", "axis-x").attr("transform", "translate(0," + height + ")").call((0, _d3Axis.axisBottom)(x));

      g.append("g").attr("class", "axis-y").call((0, _d3Axis.axisLeft)(y)).append("text").attr("fill", "#000").attr("transform", "rotate(-90)").attr("y", 6).attr("dy", "0.71em").attr("text-anchor", "end").text("Service (hours)");

      // Draw series
      var seriesLine = g.selectAll(".series").data(series).enter().append("g").attr("class", "series");

      seriesLine.append("path") // path
      .attr("class", "line").attr("id", function (d) {
        return "line-" + d.id;
      }).attr("d", function (d) {
        return l(d.values);
      }).style("stroke", function (d) {
        return z(d.id);
      }).style("stroke-width", 1.5).style("fill", "none").on("mouseover", mouseover).on("mouseout", mouseout);

      seriesLine.append("text") // path label
      .attr("class", "text").attr("id", function (d) {
        return "text-" + d.id;
      }).attr("x", width + 15).attr("y", 0).attr("dy", function (d) {
        return 1.25 * d.idx + 1.0 + "em";
      }).attr("fill", function (d) {
        return z(d.id);
      }).style("font", "12px sans-serif").text(function (d) {
        return d.short_sha1;
      }).on("mouseover", mouseover).on("mouseout", mouseout);
    }
  });
});