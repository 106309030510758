define('dispatcher/mixins/issues-route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {
    queryParams: {
      issue_type: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var category = this.get('category');
      params['category'] = category;
      if (params['issue_type'] === undefined || params['issue_type'] === 'null') {
        delete params['issue_type'];
      }
      var issues = this.store.query('issue', params, { reload: true });
      var adapter = this.get('store').adapterFor('issues');
      var issue_categories_url = adapter.urlPrefix() + '/issues/categories';
      var promise = adapter.ajax(issue_categories_url, 'get', {});

      return Ember.RSVP.hash({
        issues: issues,
        issueTypes: promise.then(function (response) {
          return response[category];
        })
      });
    }
  });
});