define('dispatcher/users/edit/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.find('user', params['user_id']);
    },
    actions: {
      update: function update() {
        var flashMessages = Ember.get(this, 'flashMessages');
        var self = this;
        var user = self.currentModel;
        user.save().then(function () {
          flashMessages.add({
            message: "User updated!",
            type: 'success',
            sticky: true
          });
          self.transitionTo('users.show', user);
        }).catch(function (error) {
          flashMessages.add({
            message: 'Error(s) updating user: ' + error.message,
            type: 'danger',
            sticky: true
          });
        });
      }
    }
  });
});