define("dispatcher/helpers/number-as-pips", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberAsPips = numberAsPips;
  function numberAsPips(params) {
    var selectedNumber = params[0];
    var totalNumber = params[1];
    var string = "";
    for (var i = 0; i < selectedNumber; i++) {
      string = string + "■";
    }
    for (var _i = 0; _i < totalNumber - selectedNumber; _i++) {
      string = string + "□";
    }
    return string;
  }

  exports.default = Ember.Helper.helper(numberAsPips);
});