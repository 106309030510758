define('dispatcher/feeds/bulk-add/controller', ['exports', 'dispatcher/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    inputUrls: null,
    outputResults: [],
    actions: {
      add: function add() {
        var _this = this;

        var urls = this.get("inputUrls").split("\n");
        urls.forEach(function (url) {
          if (url !== "") {
            _this.store.query('feed', { "url": url }).then(function (response) {
              var onestopId = "";
              if (response.meta.total == 1) {
                onestopId = response.get('firstObject.onestop_id');
              }
              _this.get("outputResults").addObject({
                url: url,
                onestopId: onestopId
              });
            });
          }
        });
      },
      clear: function clear() {
        this.get("outputResults").clear();
      }
    }
  });
});