define('dispatcher/routingcheck/controller', ['exports', 'dispatcher/mixins/paginated-sortable-controller'], function (exports, _paginatedSortableController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatedSortableController.default, {
    per_page: 10,
    departure_date_times: [0, 7, 14].map(function (offset) {
      var today = new Date();
      var tomorrow = new Date(today);
      tomorrow.setDate(today.getDate() + offset);
      return tomorrow;
    })
  });
});