define('dispatcher/valhalla-route/service', ['exports', 'dispatcher/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    queue: [],
    rate_limit: _environment.default.valhallaRateLimit,
    api_key: _environment.default.valhallaApiKey,
    url: _environment.default.valhallaHost,
    url_lrm: "http://valhalla.github.io/demos/routing/index.html#",
    run: function run() {
      var job = this.get('queue').shift();
      return Ember.$.getJSON(job.url).then(function (trip) {
        job.success(trip);
      }, function (failure) {
        job.failure(failure);
      });
    },
    poll: function poll() {
      // Run the throttled function
      Ember.run.throttle(this, this.run, this.get('rate_limit'));
      // Return if no items left to process
      if (this.get('queue').length) {
        // Re-poll
        var self = this;
        Ember.run.later(function () {
          self.poll();
        }, this.get('rate_limit'));
      }
    },
    add: function add(url, success, failure) {
      var job = {
        url: url,
        success: success,
        failure: failure
      };
      this.get('queue').push(job);
      this.poll();
      return job;
    },
    empty: function empty() {
      console.log('empty queue');
      this.get('queue').setObjects([]);
    },

    // Get Valhalla Route
    get_url: function get_url(params) {
      var api_key = this.get('api_key');
      var url = this.get('url');
      return url + "?" + Ember.$.param({ json: JSON.stringify(params), api_key: api_key });
    },
    get_url_lrm: function get_url_lrm(params) {
      return this.get('url_lrm') + $.param({
        loc: [params.locations[0].lat, params.locations[0].lon].join(','),
        locations: JSON.stringify(params.locations),
        costing: JSON.stringify(params.costing),
        costing_options: JSON.stringify(params.costing_options),
        datetime: JSON.stringify(params.date_time)
      });
    },
    route_params: function route_params(origin_coords, destination_coords, departure_date_time) {
      return {
        locations: [{ lon: origin_coords[0], lat: origin_coords[1], type: 'break' }, { lon: destination_coords[0], lat: destination_coords[1], type: 'break' }],
        costing: 'multimodal',
        costing_options: {
          transit: {
            use_bus: 0.3,
            use_rail: 0.6,
            use_transfers: 0.3
          }
        },
        date_time: {
          type: 1,
          value: departure_date_time.toISOString().slice(0, 16)
        }
      };
    }
  });
});