define('dispatcher/components/stop-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      switchPopup: function switchPopup(entity) {
        this.stop.set('isOpen', false);
        entity.set('isOpen', true);
      },
      newPlatform: function newPlatform() {
        this.stop.set('isOpen', false);
        var entity = this.stop.newPlatform();
        entity.set('isOpen', true);
      },
      newEgress: function newEgress() {
        this.stop.set('isOpen', false);
        var entity = this.stop.newEgress();
        entity.set('isOpen', true);
      }
    }
  });
});