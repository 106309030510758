define('dispatcher/components/route-map/route-stop-pattern/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    color: Ember.computed(function () {
      return '#000';
    }),
    onestop_id: '',
    actions: {
      editEntityAdded: function editEntityAdded(addEvent) {
        this.sendAction('editEntityAdded', addEvent.target, this.get('onestop_id'));
      }
    }
  });
});