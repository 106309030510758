define('dispatcher/operator/serializer', ['exports', 'dispatcher/application/serializer', 'ember-data'], function (exports, _serializer, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _serializer.default.extend(_emberData.default.EmbeddedRecordsMixin, {
		primaryKey: 'onestop_id',
		attrs: {
			feeds: {
				key: 'represented_in_feed_onestop_ids',
				deserialize: 'ids'
			}
		}
	});
});