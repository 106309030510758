define('dispatcher/issues/feed-fetch/show/route', ['exports', 'dispatcher/mixins/issues-route'], function (exports, _issuesRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_issuesRoute.default, {
    currentUser: Ember.inject.service(),
    model: function model(params) {
      this.store.unloadAll('changeset');
      this.store.unloadAll('change_payload');
      var changeset = this.store.createRecord('changeset', {
        user: this.get('currentUser.user'),
        notes: 'Issue resolution:'
      });
      changeset.get('change_payloads').createRecord();
      var users = this.store.query('user', { per_page: false });
      var self = this;
      return self.store.findRecord('issue', params['issue_id'], { reload: true }).then(function (selectedIssue) {
        var feed_id = selectedIssue.get('entities_with_issues').get('firstObject').get('onestop_id');
        var feed = self.store.findRecord('feed', feed_id);
        return Ember.RSVP.hash({
          selectedIssue: selectedIssue,
          feed: feed,
          changeset: changeset,
          users: users
        });
      });
    }
  });
});