define('dispatcher/issues/feed-fetch/show/controller', ['exports', 'dispatcher/mixins/issues-controller', 'dispatcher/mixins/issues-resolving-changeset-controller', 'dispatcher/mixins/issues-close-controller'], function (exports, _issuesController, _issuesResolvingChangesetController, _issuesCloseController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_issuesController.default, _issuesResolvingChangesetController.default, _issuesCloseController.default, {
    selected: false,

    rootRoute: 'issues.feed-fetch',

    postSuccessTransition: function postSuccessTransition() {
      this.transitionToRoute('feeds.show', this.model.feed.get('onestop_id'));
    },

    getChanges: function getChanges() {
      var thisIssue = this.model.selectedIssue;
      var ret = {};
      ret['action'] = 'createUpdate';
      ret['issuesResolved'] = [Number(thisIssue.get('id'))];
      ret['feed'] = { onestopId: this.model.feed.get('onestop_id'), url: this.model.feed.get('url') };
      return [ret];
    },

    actions: {
      closeDialog: function closeDialog() {
        this.set('closeMessage', { show: true, message: 'Closing Feed fetch issues is unavailable.' });
      },
      closeIssue: function closeIssue() {}
    }
  });
});