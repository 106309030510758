define('dispatcher/feed-versions/upload/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['feed_onestop_id'],
    feed_onestop_id: null
  });
});