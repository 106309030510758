define('dispatcher/feeds/index/controller', ['exports', 'dispatcher/mixins/paginated-sortable-controller'], function (exports, _paginatedSortableController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatedSortableController.default, {
    active_feed_version_update: false,
    active_feed_version_expired: null,
    active_feed_version_valid: null,
    active_feed_version_import_level: null,
    latest_fetch_exception: null,
    latest_feed_version_import_status: '',
    feed_format: null,
    tag_key: null,
    tag_value: null
  });
});