define('dispatcher/mixins/issues-resolving-changeset-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    getChanges: function getChanges() {},
    emptyChangeset: function emptyChangeset() {
      var self = this;
      var changeset = self.store.createRecord('changeset', {
        user: self.get('currentUser.user'),
        notes: 'Issue resolution:'
      });
      changeset.get('change_payloads').createRecord();
      this.set('model.changeset', changeset);
    },
    pollChangesetApply: function pollChangesetApply(resolvingIssue, url, applicationAdapter) {
      var self = this;
      var flashMessages = Ember.get(this, 'flashMessages');
      applicationAdapter.ajax(url, 'post').then(function (response) {
        if (response.status === 'complete') {
          flashMessages.clearMessages();
          flashMessages.add({
            message: 'Successfully resolved issue ' + resolvingIssue.id + '. Click to close.',
            type: 'success',
            sticky: true
          });
          self.postSuccessTransition();
        } else if (response.status === 'queued') {
          Ember.run.later(self.pollChangesetApply.bind(self, resolvingIssue, url, applicationAdapter), 2000);
        } else if (response.status === 'error') {
          flashMessages.clearMessages();
          flashMessages.add({
            message: 'Error resolving issue ' + resolvingIssue.id + '. ' + response.errors + '. Click to close.',
            type: 'danger',
            sticky: true
          });
          // clean the changeset, but leave edits.
          self.emptyChangeset();
        } else {
          Ember.run.later(self.pollChangesetApply.bind(self, resolvingIssue, url, applicationAdapter), 2000);
        }
      }).catch(function (e) {
        flashMessages.clearMessages();
        flashMessages.add({
          message: 'Error resolving issue ' + resolvingIssue.id + '. ' + e.errors[0].message + '. Click to close.',
          type: 'danger',
          sticky: true
        });
        // clean the changeset, but leave edits.
        self.emptyChangeset();
      });
    },

    actions: {
      saveChangeset: function saveChangeset() {
        var flashMessages = Ember.get(this, 'flashMessages');
        var self = this;
        return self.model.changeset.save().then(function (changeset) {
          self.set('applyingSpinner', true);
          return changeset.apply_async();
        }).then(function (response) {
          self.set('applyingSpinner', false);
          self.set('showChangeset', false);
          flashMessages.info('Applying changeset to resolve issue ' + self.model.selectedIssue.id);
          var applicationAdapter = self.store.adapterFor('changeset');
          var modelUrl = applicationAdapter.buildURL('changeset', self.get('model.changeset.id'));
          var applyUrl = modelUrl + '/apply_async';
          self.pollChangesetApply(self.model.selectedIssue, applyUrl, applicationAdapter);
        }).catch(function (error) {});
      },
      showChangeset: function showChangeset() {
        var payload = { changes: this.getChanges() };
        this.model.changeset.get('change_payloads').get('firstObject').set('payload', payload);
        this.set('showChangeset', true);
      },
      hideChangeset: function hideChangeset() {
        this.set('showChangeset', false);
      }
    }
  });
});