define('dispatcher/operator/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Operator = _emberData.default.Model.extend({
    feeds: _emberData.default.hasMany('feed', {
      async: true
    }),
    identifiers: _emberData.default.attr(),
    name: _emberData.default.attr('string'),
    short_name: _emberData.default.attr('string'),
    onestop_id: Ember.computed.alias('id'),
    country: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    metro: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    geometry: _emberData.default.attr(),
    tags: _emberData.default.attr()
  });

  exports.default = Operator;
});