define('dispatcher/feed-version-info/serializer', ['exports', 'dispatcher/application/serializer'], function (exports, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend({
    attrs: {
      json: 'data'
    }
  });
});