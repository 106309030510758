define('dispatcher/issues/station-hierarchy/index/controller', ['exports', 'dispatcher/mixins/issues-controller', 'dispatcher/mixins/paginated-sortable-controller'], function (exports, _issuesController, _paginatedSortableController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_issuesController.default, _paginatedSortableController.default, {
    rootRoute: 'issues.station-hierarchy',
    hasNextPage: Ember.computed("model.issues.meta.next", function () {
      if (Ember.isPresent(this.get('model.issues.meta.next'))) {
        return true;
      } else {
        return false;
      }
    })
  });
});