define('dispatcher/user/serializer', ['exports', 'ember-data', 'dispatcher/application/serializer'], function (exports, _emberData, _serializer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _serializer.default.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      // id: { serialize: false }
    }
  });
});