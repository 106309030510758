define('dispatcher/user/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    changesets: _emberData.default.hasMany('changeset'),

    email: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    affiliation: _emberData.default.attr('string'),
    user_type: _emberData.default.attr('string'),
    admin: _emberData.default.attr('boolean')
  });
});