define('dispatcher/mixins/paginated-sortable-route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    queryParams: {
      offset: {
        refreshModel: true
      },
      per_page: {
        refreshModel: true
      },
      sort_key: {
        refreshModel: true
      },
      sort_order: {
        refreshModel: true
      }
    }
  });
});