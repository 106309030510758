define('dispatcher/helpers/issue-type-desc', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    typeDescription: Ember.inject.service('issue-type-desc'),
    compute: function compute(params) {
      return this.get('typeDescription').typeDescription(params[0]);
    }
  });
});