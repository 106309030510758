define('dispatcher/feed-version-info/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    feed_version: _emberData.default.belongsTo('feed-version', { async: true }),
    json: _emberData.default.attr(),
    type: _emberData.default.attr(),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date')
  });
});