define('dispatcher/feeds/edit/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    changeset: Ember.inject.service(),
    actions: {
      update: function update() {
        var payload = encodeURI(JSON.stringify(this.get('changeset').createChangesetPayloadFromLocalEdits()));
        var notes = 'Edits to feed with Onestop ID of ' + this.get('model.id');
        this.transitionToRoute('changesets.new', {
          queryParams: {
            payload: payload,
            notes: notes
          }
        });
      }
    }
  });
});