define('dispatcher/users/index/route', ['exports', 'dispatcher/mixins/paginated-sortable-route'], function (exports, _paginatedSortableRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatedSortableRoute.default, {
    model: function model(params) {
      return this.store.query("user", params);
    }
  });
});