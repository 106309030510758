define('dispatcher/entity-with-issue/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    issue: _emberData.default.belongsTo('issue', { inverse: 'entities_with_issues' }),
    onestop_id: _emberData.default.attr('string'),
    entity_type: _emberData.default.attr('string'),
    entity_attribute: _emberData.default.attr('string'),
    entity: Ember.computed('onestop_id', 'entity_type', function () {
      return this.store.findRecord(this.get('entity_type'), this.get('onestop_id'));
    })
  });
});