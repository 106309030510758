define('dispatcher/mixins/issues-close-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      toggleCloseMessage: function toggleCloseMessage() {
        this.set('closeMessage.show', false);
      },
      closeIssue: function closeIssue() {
        this.model.selectedIssue.set('open', false);
        var self = this;
        self.model.selectedIssue.save().then(function () {
          self.set('closeMessage.show', false);
          self.postCloseTransition();
        }).catch(function (error) {
          self.set('closeMessage', { show: true, error: true, message: 'Error closing issue ' + self.model.selectedIssue.id + '. ' + error.message });
        });
      },
      closeDialog: function closeDialog() {
        this.set('closeMessage', { show: true, message: 'Close issue ' + this.model.selectedIssue.id });
      }
    }
  });
});