define('dispatcher/operators/index/controller', ['exports', 'dispatcher/mixins/paginated-sortable-controller'], function (exports, _paginatedSortableController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_paginatedSortableController.default, {
    tag_key: null,
    tag_value: null,
    country: null,
    state: null,
    metro: null
  });
});