define('dispatcher/operators/index/route', ['exports', 'dispatcher/mixins/paginated-sortable-route', 'dispatcher/config/environment'], function (exports, _paginatedSortableRoute, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_paginatedSortableRoute.default, {
    queryParams: {
      tag_key: {
        refreshModel: true
      },
      tag_value: {
        refreshModel: true
      },
      country: {
        refreshModel: true
      },
      state: {
        refreshModel: true
      },
      metro: {
        refreshModel: true
      },
      without_feed: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var geographies = Ember.$.get(_environment.default.datastoreHost + '/api/v1/operators/aggregate').then(function (response) {
        return {
          countries: Object.keys(response.country),
          states: Object.keys(response.state),
          metros: Object.keys(response.metro)
        };
      });
      var operators = this.store.query('operator', params);
      return Ember.RSVP.hash({
        geographies: geographies,
        operators: operators
      });
    }
  });
});