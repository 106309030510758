define('dispatcher/components/route-map/stop-marker/component', ['exports', 'dispatcher/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    onestop_id: '',
    markerStopIcon: L.icon({
      iconUrl: '/assets/images/station.png',
      iconSize: [36, 54],
      iconAnchor: [18, 54],
      popupAnchor: [0, -54]
    }),
    actions: {
      editEntityAdded: function editEntityAdded(addEvent) {
        this.sendAction('editEntityAdded', addEvent.target, this.get('onestop_id'));
      }
    }
  });
});